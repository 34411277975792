import { all, fork } from "redux-saga/effects";
import { userSagas } from "./sagas.user";
import { predictionSagas } from "./sagas.predictions";
import { appSagas } from "./sagas.app";
import { dashboardSagas } from "./sagas.dash";

export function* allSagas(){
    yield all([
        ...appSagas,
        ...userSagas,
        ...predictionSagas,
        ...dashboardSagas
    ])
}
function* rootSaga() {
    yield fork (allSagas);
}; 
export { rootSaga };