import { call, put, takeLatest } from "redux-saga/effects";
import * as Api from '../../services/api.service'
import { httpStatusType } from "../../config/enums";
import { callWrapper } from "./sagas.callWrapper";
import {  clearFromLocalStorage, httpResponseStatus, saveToLocalStorage, } from "../../utils/apiHelper";
import {
    apiGetMasterDataFailed,
    apiSetMasterData,
    userLogin,
    userLoginFailed,
    userLoginSuccess,
    userLogout,
    userLogoutSuccess
} from '../actions'

export function* storeAuthContext(data) {
    const { token } = data;
    yield call(saveToLocalStorage, "token", `Bearer ${token}`);
}
  
export function* removeAuthContext() {
    yield call(clearFromLocalStorage, "token");
}

export function* getMasterData(data) {
    try {
        const res = yield callWrapper(Api.getMasterData, {
            ...data
        });
        if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
            const data = JSON.parse(res?.data);
            yield put(apiSetMasterData({ ...data}));
        } else {
          yield put(apiGetMasterDataFailed());
        }
    } catch (err) {
      yield put(apiGetMasterDataFailed());
    }
}

export function* login(action) {
    try {
        if (userLogin.match(action)) {
        const res = yield callWrapper(Api.login, {
            ...action.payload,
            tokenRequired: false,
          });
        if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
            const data = JSON.parse(res?.data);
            data.token=res.headers.authtoken
            yield put(userLoginSuccess({ ...data, ...action.payload}));
            yield call(storeAuthContext, { ...data, ...action.payload });
            yield call(getMasterData,['categories', 'duration','bid-range','countries'])
        } else {
          yield put(userLoginFailed());
        }
      }
    } catch (err) {
      yield put(userLoginFailed());
    }
  }
  
  export function* logout(action) {
    try {
        if (userLogout.match(action)) {
            const res = yield callWrapper(Api.logout);
            if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
                yield call(removeAuthContext);
                yield put(userLogoutSuccess());
            }
            // yield call(removeAuthContext);
            // yield put(userLogoutSuccess());
       
      }
    } catch (err) {
      yield put(userLoginFailed());
    }
  }

  

  export const userSagas = [
    takeLatest(userLogin.type, login),
    takeLatest(userLogout.type, logout)
  ];