// BarChart.js
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';

const NivoBarChart = ({ decisionTreeGraphdata, xAxisLabel, yAxisLabel, right, height }) => {

    const data = Object.keys(decisionTreeGraphdata).map(item => ({ label: item, value: decisionTreeGraphdata[item] }))
    // const data=decisionTreeGraphdata

    const colors = {
        'CONTINGENCY': 'rgba(193, 193, 193, 1)',
        'RISK_PROVISION': 'rgba(216, 34, 55, 1)',
        'PM_CONTINGENCY':'rgba(230, 230, 230, 1)',
        'MITIGATED': 'rgba(47, 0, 7, 1)',
        'COST': 'rgba(94, 0, 14, 1)',
        'NO_FCM_IMPACT': 'rgba(83, 85, 83, 1)',
        'REVENUE': 'rgba(192, 98, 112, 1)',
    }
    // const colors = {
    //     'RISK_PROVISION': 'yellow',
    //     'MITIGATED': 'rgb(99, 102, 241)',
    //     'CONTINGENCY': 'yellow',
    //     'COST': 'rgb(99, 102, 241)',
    //     'NO_FCM_IMPACT': 'yellow',
    //     'REVENUE': 'rgb(99, 102, 241)',
    // }
    

    const theme = {
        axis: {
            domain: {
                line: {
                    stroke: '#777777',
                    strokeWidth: 1
                }
            },
            legend: {
                "text": {
                    "fontSize": 12,
                    "fill": "#000000",
                    "outlineWidth": 6,
                    "outlineColor": "transparent"
                }
            },
            textColor: '#eee',
            fontSize: '4px',
            tickColor: '#eee',
        },
        legends: { text: { fontSize: 30 } }
    }   
    
    // const theme = {
    //     axis: {
    //       legend: {
    //         text: {
    //           fill: "#aaaaaa"
    //         }
    //       },
    //         domain: {
    //             line: {
    //                 "stroke": "#777777",
    //                 "strokeWidth": 1
    //             }
    //       }
    //     },
    //   };

    const getColor = bar => {
        return colors[bar.indexValue]
    }

  return (
    <div style={{ height }}>
          <ResponsiveBar
            theme={theme}
            data={data}
            keys={['value']} // Specify the data key
            indexBy="label" // Specify the key to use as x-axis labels
            margin={{ top: 30, right: right, bottom: 50, left: 40 }}
            padding={0.7}
            colors={getColor}
            axisTop={null}
            labelSkipHeight={5}
            labelSkipWidth={5}
            axisRight={null}
            enableLabel={false}
            axisBottom={{
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legend: xAxisLabel,
                legendPosition: 'middle',
                legendOffset: 43,
                legendTextStyle: {
                    fill: 'red', // Set the text color to red
                    fontWeight: 'bold', // Set the font weight to bold
                  },
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 0,
                ticksPosition:"before",
                tickRotation: 0,
                legend: yAxisLabel,
                legendPosition: 'middle',
                legendOffset: -34,
            }}
            // labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'top-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemsSpacing: 2,
                    symbolSize: 20,
                    itemDirection: 'left-to-right',
                }
            ]}
        />
    </div>
  );
};

export default NivoBarChart;
