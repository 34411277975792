import { informationType } from "../config/enums";

const showInfo = ({ toast, message }) => {
  return toast.info(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const showSuccess = ({ toast, message }) => {
  return toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const showWarning = ({ toast, message }) => {
  return toast.warn(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const showError = ({ toast, message }) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const show = ({
  toast,
  message,
  type = informationType.INFO,
}) => {
  switch (type) {
    case informationType.ERROR:
      showError({
        toast,
        message: message || '',
      });
      break;
    case informationType.SUCCESS:
      showSuccess({
        toast,
        message: message || '',
      });
      break;
    case informationType.INFO:
      showInfo({
        toast,
        message: message || '',
      });
      break;
    case informationType.WARNING:
      showWarning({
        toast,
        message: message || '',
      });
      break;
  }
};
