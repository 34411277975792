import { createReducer } from "@reduxjs/toolkit";
import {
  getPredictionDataFailed,
    setPredictionData,
    reSetPredictionData
} from "../actions";

const initalState = {
    questionData: {},
    predictionsData: {},
    riskQuantificationData: {}
};

const reducer = createReducer(initalState, (builder) => {
    return builder
        .addCase(setPredictionData, (state, action) => {
            state.predictionsData = action.payload?.predicted_decisiontree;
            state.riskQuantificationData = action.payload?.predicted_fcmEffectUSD;
        })
        .addCase(getPredictionDataFailed, (state) => {
            state.predictionsData = {};
        })
        .addCase(reSetPredictionData, (state) => {
            state.predictionsData = {};
            state.riskQuantificationData={}
        })
        
});
export { initalState as predictionsInitialState, reducer as predictionsReducer };
