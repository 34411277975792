// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.side-nav-main {
  height: 100%;
  width: 15%;
  position: fixed;
  left: 0;
  z-index: 2;
  background-color: #fafafa;
  padding-top: 3%;
  border-top-right-radius: 5px;
  border-right: 0.1px solid rgb(213, 200, 200);
}
.side-nav-main .logout-button {
  margin-left: 20px;
  cursor: pointer;
}
.side-nav-main .logout-button:hover {
  box-shadow: 1px 1px 1px white;
  opacity: 0.5;
}

.main-nav {
  height: 80%;
}

.active-nav-item {
  background-color: rgba(219, 224, 228, 0.768627451);
  border-radius: 5px;
}

.side-nav-main .nav-item {
  padding: 1% 1% 2% 18%;
  text-decoration: none;
  margin-bottom: 5%;
  font-size: 18px;
  display: block;
}
.side-nav-main .nav-item a {
  color: #000000;
  text-decoration: none;
}
.side-nav-main .nav-item .svg-icon-nav {
  display: inline-block;
}
.side-nav-main .nav-item .nav-item-name {
  display: inline;
  vertical-align: super;
}
.side-nav-main .nav-item:hover {
  font-weight: bold;
  opacity: 0.7;
}

.side-nav-main .nav-item:hover {
  color: #f1f1f1;
}`, "",{"version":3,"sources":["webpack://./src/common/components/sidenav/sidenav.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,UAAA;EACA,eAAA;EACA,OAAA;EAEA,UAAA;EACA,yBAAA;EAEA,eAAA;EACA,4BAAA;EACA,4CAAA;AADJ;AAII;EACI,iBAAA;EACA,eAAA;AAFR;AAGQ;EACI,6BAAA;EACA,YAAA;AADZ;;AAKA;EACI,WAAA;AAFJ;;AAIA;EACI,kDAAA;EAKA,kBAAA;AALJ;;AAQA;EACI,qBAAA;EAEA,qBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;AANJ;AAOI;EACI,cAAA;EAEA,qBAAA;AANR;AAQI;EACI,qBAAA;AANR;AAQI;EACI,eAAA;EACA,qBAAA;AANR;AAQI;EACI,iBAAA;EACA,YAAA;AANR;;AAUA;EACI,cAAA;AAPJ","sourcesContent":[".side-nav-main {\n    height: 100%;\n    width: 15%;\n    position:fixed;\n    left: 0;\n    // top: 20px;\n    z-index: 2;\n    background-color:#fafafa;\n    // background-color:#111927;\n    padding-top: 3%;\n    border-top-right-radius: 5px;\n    border-right: 0.1px solid rgb(213, 200, 200);\n    // border-right: 0.1px solid black;\n    // border-top: 0.1px solid grey;\n    .logout-button{\n        margin-left: 20px;\n        cursor: pointer;\n        &:hover{\n            box-shadow: 1px 1px 1px white;\n            opacity: 0.5;\n        }\n    }\n}\n.main-nav{\n    height: 80%;\n}\n.active-nav-item{\n    background-color: #dbe0e4c4;\n    // background-color: #c1c6ca;\n    // background-color: #0b263e;\n    // border: 1px solid #eddcdc;\n    // border: 1px solid #100505;\n    border-radius: 5px;\n}\n\n.side-nav-main .nav-item {\n    padding: 1% 1% 2% 18%;\n    // padding: 8px 8px 8px 60px;\n    text-decoration: none;\n    margin-bottom: 5%;\n    font-size: 18px;\n    display: block;\n    a{\n        color: #000000;\n        // color: #ffffff;\n        text-decoration: none;\n    }\n    .svg-icon-nav{\n        display: inline-block;\n    }\n    .nav-item-name{\n        display: inline;\n        vertical-align:super;\n    }\n    &:hover{\n        font-weight: bold;\n        opacity: 0.7;\n    }\n}\n\n.side-nav-main .nav-item:hover {\n    color: #f1f1f1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
