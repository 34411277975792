import { combineReducers } from "@reduxjs/toolkit";
import { userReducer } from "./reducer.user";
import { appReducer } from "./reducer.app";
import { errorReducer } from "./reducer.error";
import { predictionsReducer } from "./reducer.predictions";
import { dashReducer } from "./reducer.dash";

const reducers = {
    user: userReducer,
    app: appReducer,
    error: errorReducer,
    predictions: predictionsReducer,
    dash:dashReducer
};

function createRootReducer() {
  const rootReducer = combineReducers({
    ...reducers,
  });

  return rootReducer;
}

export { createRootReducer };