
 
const Input = ({label,type,name,placeholder,required,disabled,onKeyDown, className, errorMessage, ...rest}) => {
  return (
      <div className="input-container">
            
          <div>
            <div className="label-input">
                <label htmlFor={name}>
                    {label} <span>{required ? "*" : ""}</span>
                </label>
            </div>
            <div className={className}>
                <input
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    required={required}
                    disabled={disabled}
                    onKeyDown={onKeyDown}
                    {
                        ...rest
                    }
                ></input>
                {errorMessage && (
                    <div className="error-msg">
                    <span>{errorMessage}</span>
                    </div>
              )}
            </div>
          </div>
      </div>
  )
}

export default Input