import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import Preloader from "../preloader/preloader";
import HitachiEnergy from '../../../assets/logos/hitachi_energy.png'
import HitachiNextLogo from '../../../assets/logos/hitachi-next.png.png'
import bgimage from '../../../assets/bgimgs/image.png'
import { reSetPredictionData, userLogout } from "../../../redux/actions";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { Button } from "@mui/material";
import HistoryIcon from '@mui/icons-material/History';
import PredictionHistory from "../../../features/history/history";



const Layout = (props) => {
    const { pathname } = useLocation();
    const navigate = useNavigate()
    const { inProgress } = useSelector((state) => state.app);

    const { isLoggedIn } = useSelector((state) => state.user);

    const [showUserMenu, setShowUserMenu] = useState(false)
    const [showHistory, setShowHistory] = useState(false)
    useEffect(() => {
        console.log(showHistory)
    },[showHistory])

    const profileRef = useRef()
    const profileDetailsRef = useRef()

    const dispatch = useDispatch();

    const logoutHandler = () => {
        dispatch(reSetPredictionData())
        dispatch(userLogout());
    }
    
    const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
    const headerRef = useRef(null);

    // handle scroll event
    const handleScroll = (elTopOffset, elHeight) => {
        if (window.pageYOffset > (elTopOffset + elHeight)) {
        setSticky({ isSticky: true, offset: elHeight });
        } else {
        setSticky({ isSticky: false, offset: 0 });
        }
    };

    const hideUserDetails = (e) => {
        if (profileDetailsRef.current && !profileDetailsRef?.current.contains(e?.target) && !profileRef?.current.contains(e?.target)) {
            if(showUserMenu) setShowUserMenu(!showUserMenu)
        }
    }

    // add/remove scroll event listener
    useEffect(() => {
        if (pathname !== '/login') {
            var header = headerRef.current.getBoundingClientRect();
            const handleScrollEvent = () => {
            handleScroll(header.top, header.height)
            }
    
            window.addEventListener('scroll', handleScrollEvent);
    
            return () => {
            window.removeEventListener('scroll', handleScrollEvent);
            };
        }
    }, []);
    const predictions_path = ['/predictions','/']
      

    return (
        <div className="layout-wrapper" onClick={hideUserDetails}>
            <ToastContainer />
            { pathname !== '/login' &&
                <>
                    <div className="hitachi-next-navbar">
                        <div className="logo-container">
                            <div className="logo">
                                <img alt="Hitachi" title="Hitachi" src={HitachiNextLogo} />
                            </div>  
                        </div>
                        <div className="profile-holder" onClick={()=>{setShowUserMenu(!showUserMenu)}} ref={profileRef}>
                                <PersonOutlineIcon/>
                                <div className="user-name">John Mathew</div>
                        </div>
                        <div className={`${showUserMenu ? 'active-opacity' : 'opacity-none'} user-menu`} ref={profileDetailsRef}>
                        <div className="svg-user-icon">
                            <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_13_3365" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="49" height="48">
                            <rect x="0.5" width="48" height="48" fill="#D9D9D9"/>
                            </mask>
                            <g mask="url(#mask0_13_3365)">
                            <path d="M12.2 34.2C13.9 32.9 15.8 31.875 17.9 31.125C20 30.375 22.2 30 24.5 30C26.8 30 29 30.375 31.1 31.125C33.2 31.875 35.1 32.9 36.8 34.2C37.9667 32.8333 38.875 31.2833 39.525 29.55C40.175 27.8167 40.5 25.9667 40.5 24C40.5 19.5667 38.9417 15.7917 35.825 12.675C32.7083 9.55833 28.9333 8 24.5 8C20.0667 8 16.2917 9.55833 13.175 12.675C10.0583 15.7917 8.5 19.5667 8.5 24C8.5 25.9667 8.825 27.8167 9.475 29.55C10.125 31.2833 11.0333 32.8333 12.2 34.2ZM24.5 26C22.5333 26 20.875 25.325 19.525 23.975C18.175 22.625 17.5 20.9667 17.5 19C17.5 17.0333 18.175 15.375 19.525 14.025C20.875 12.675 22.5333 12 24.5 12C26.4667 12 28.125 12.675 29.475 14.025C30.825 15.375 31.5 17.0333 31.5 19C31.5 20.9667 30.825 22.625 29.475 23.975C28.125 25.325 26.4667 26 24.5 26ZM24.5 44C21.7333 44 19.1333 43.475 16.7 42.425C14.2667 41.375 12.15 39.95 10.35 38.15C8.55 36.35 7.125 34.2333 6.075 31.8C5.025 29.3667 4.5 26.7667 4.5 24C4.5 21.2333 5.025 18.6333 6.075 16.2C7.125 13.7667 8.55 11.65 10.35 9.85C12.15 8.05 14.2667 6.625 16.7 5.575C19.1333 4.525 21.7333 4 24.5 4C27.2667 4 29.8667 4.525 32.3 5.575C34.7333 6.625 36.85 8.05 38.65 9.85C40.45 11.65 41.875 13.7667 42.925 16.2C43.975 18.6333 44.5 21.2333 44.5 24C44.5 26.7667 43.975 29.3667 42.925 31.8C41.875 34.2333 40.45 36.35 38.65 38.15C36.85 39.95 34.7333 41.375 32.3 42.425C29.8667 43.475 27.2667 44 24.5 44Z" fill="#C1C1C1"/>
                            </g>
                            </svg>
                        </div>
                        <div className="user-name">John Mathew</div>
                        <div className="user-role">Product Manager</div>
                        <div className="user-email">John.mathew@hitachienergy.com</div>
                        <div className="user-history" onClick={() => {
                            setShowHistory(true)
                            setShowUserMenu(false)
                        }}><HistoryIcon />History</div>
                        <div className="logout-container">
                            <div className="logout" onClick={logoutHandler}>
                                <LogoutIcon sx={{color:'black', cursor:'pointer'}}/>
                                <Button variant="text" sx={{color:'black', top:'50%', verticalAlign:'super'}} >Log out</Button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div ref={headerRef} className={`sticky-navbar${sticky.isSticky ? ' sticky' : ''}`}>
                        <div className="logo-container">
                                <img alt="Hitachi" title="Hitachi" src={HitachiEnergy} />
                        </div>
                        <div className="nav-items">
                            <div className={`nav-item predictions ${predictions_path.includes(pathname) ? 'active':'' }`} onClick={()=>{navigate('/predictions')}}>Predictions</div>
                        <div className={`nav-item dashboard ${pathname === '/dashboard' ? 'active' : ''}`} onClick={() => {
                            navigate('/dashboard')
                            dispatch(reSetPredictionData())
                        }}>Dashboard</div>
                        </div>
                    </div>
                </>
            }
            {
                <div className={`${pathname === '/login' ? 'login-bg' : 'content-container'}`} style={{
                    backgroundImage:{bgimage}
                }}>
                    <Preloader show={inProgress}/>
                    <div className="layout-content">
                        {!showHistory ? props.children : <PredictionHistory setShowHistory={setShowHistory} />}
                    </div>
                </div>
            }
            <div >
            </div>
        </div>
        
  )
}

export default Layout