import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from "react-redux";
import { configureAppStore } from "./redux";
import { userInitialState } from "./redux/reducer/reducer.user";
import jwt_decode from "jwt-decode";
import { Keys } from './config/envconfig';

function getAuthState() {
    try {
      const token = JSON.parse(localStorage.getItem("token")) || undefined;
  
      if (token) {
        const tokenDetails = jwt_decode(token);
  
        return {
          isLoggedIn: true,
          authData: { token, tokenDetails },
        };
      }
      return undefined;
    } catch (err) {
      return undefined;
    }
  }

const initialState = {
    user: { ...userInitialState, ...getAuthState() }
  };
  
  const store = configureAppStore(initialState);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
