import { call, delay, put } from "redux-saga/effects";
import { httpStatusType, informationDisplayType, informationType } from "../../config/enums";
import { httpResponseStatus,clearFromLocalStorage } from "../../utils/apiHelper";
import { apiCallCompleted, apiCallInProgress, userLogoutSuccess, hideError, setError } from "../actions";

export function* callWrapper(fn, args = {}) {
  const { responseDisplayType = informationDisplayType.NOTIFICATION, ...reqPayload } = args;

  try {
    if(reqPayload?.showLoader===undefined) yield put(apiCallInProgress());
    const response = yield call(fn, reqPayload);
    yield put(apiCallCompleted());
    return response;
  } catch (err) {
    yield put(apiCallCompleted());

    const { response } = err;

    const { error } = JSON.parse(response?.data || { error: { message: "Error occured" } });
      if (response?.status === 401) {
      // TODO: This if condition is temporary. Will remove this case once refresh token is implemented.
        yield call(clearFromLocalStorage, "token");
        yield put(userLogoutSuccess());
    } else {
    switch (httpResponseStatus(response?.status)) {
      case httpStatusType.BAD_REQUEST:
        yield put(
          setError({
            error,
            notificationType: responseDisplayType,
            errorType: httpStatusType.BAD_REQUEST,
            informationType: informationType.ERROR,
          })
        );
        break;
      case httpStatusType.INTERNAL_SERVER_ERROR:
        yield put(
          setError({
            error,
            notificationType: responseDisplayType,
            errorType: httpStatusType.INTERNAL_SERVER_ERROR,
            informationType: informationType.ERROR,
          })
        );
        break;
      default:
        yield put(
          setError({
            error,
            notificationType: responseDisplayType,
            errorType: httpStatusType.UNKNOWN_ERROR,
            informationType: informationType.ERROR,
          })
        );
    }
  }

    if (responseDisplayType !== informationDisplayType.FLAG) {
      yield delay(5000);
      return yield put(hideError());
    }
    return;
  }
}
