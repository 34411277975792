import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { BorderLeft } from '@mui/icons-material';

const columns = [
  { id: 'projectName', label: 'Project Name', minWidth: 50 },
  { id: 'country', label: 'Country', minWidth: 50 },
  {
    id: 'mainRiskCategory',
    label: 'Category',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'duration',
    label: 'Duration',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'bidRange',
    label: 'BidRange',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'bidValue',
    label: 'Bid Value',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'fcmEffectUSD',
    label: 'Risk Amount Estimated By PM',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'NetSalesPrice_ForecastUSD',
    label: 'As Sold Price',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'CONTINGENCY',
    label: 'CONTINGENCY',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'RISK_PROVISION',
    label: 'RISK_PROVISION',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'COST',
    label: 'COST',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'MITIGATED',
    label: 'MITIGATED',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'NO_FCM_IMPACT',
    label: 'NO_FCM_IMPACT',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  },
  {
    id: 'REVENUE',
    label: 'REVENUE',
    minWidth: 50,
    align: 'left',
    // format: (value) => value.toFixed(2),
  }
];

function createData(projectName, country, mainRiskCategory, duration, bidRange, bidValue, fcmEffectUSD, NetSalesPrice_ForecastUSD,
    CONTINGENCY, RISK_PROVISION, COST, MITIGATED, NO_FCM_IMPACT, REVENUE) {
    return {
        projectName, country, mainRiskCategory, duration, bidRange, bidValue, fcmEffectUSD, NetSalesPrice_ForecastUSD,
        CONTINGENCY: {
            usd: 1000,
            decisionValue:CONTINGENCY
        }, RISK_PROVISION: {
            usd: 1000,
            decisionValue:RISK_PROVISION
        }, COST: {
            usd: 1000,
            decisionValue:COST
        }, MITIGATED: {
            usd: 1000,
            decisionValue:MITIGATED
        }, NO_FCM_IMPACT: {
            usd: 1000,
            decisionValue:NO_FCM_IMPACT
        }, REVENUE: {
            usd: 1000,
            decisionValue:REVENUE
        }
    };
}

const rows = [
    createData('Project 1', 'India', '1. Customer', '5', '0 - 2000', '1500', '1000', '1432',
    0, 0.9900000095367432, 0, 0.009999999776482582,0,0.009999999776482582),
    createData('Project 1', 'India', '1. Customer', '5', '0 - 2000', '1500', '1000', '1432',
    0, 0.9900000095367432, 0, 0.009999999776482582,0,0.009999999776482582),
    createData('Project 1', 'India', '1. Customer', '5', '0 - 2000', '1500', '1000', '1432',
    0, 0.9900000095367432, 0, 0.009999999776482582,0,0.009999999776482582),
    createData('Project 1', 'India', '1. Customer', '5', '0 - 2000', '1500', '1000', '1432',
    0, 0.9900000095367432, 0, 0.009999999776482582,0,0.009999999776482582),
    createData('Project 1', 'India', '1. Customer', '5', '0 - 2000', '1500', '1000', '1432',
    0, 0.9900000095367432, 0, 0.009999999776482582,0,0.009999999776482582),
    createData('Project 1', 'India', '1. Customer', '5', '0 - 2000', '1500', '1000', '1432',
    0, 0.9900000095367432, 0, 0.009999999776482582,0,0.009999999776482582),
];

export default function HistoryTable() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 640 }}>
        <Table stickyHeader aria-label="history table">
          <TableHead>
            <TableRow>
                <TableCell align="center" colSpan={8} sx={{ borderRight: '0.5px solid #000' }}>
                    Input 
                </TableCell>
                <TableCell align="center" colSpan={6}>
                    Decision Tree Values
                </TableCell>
            </TableRow>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column,index) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align} sx={{textWrap:'nowrap'}} >
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                                  : index > 7 ? <div>
                                      <div>{value.decisionValue}</div>
                                      <hr/>
                                      <div>{value.usd}</div>
                                  </div> : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}