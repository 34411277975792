import React, { useEffect, useState } from 'react'
import { Formik,Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import Input from '../../common/components/input/input';
import { initialValues, validationSchema } from "./utils";
import { userLogin } from '../../redux/actions';
import Layout from '../../common/components/layout/Layout';
import HitachiEnergy from '../../assets/logos/hitachi_energy.png'

// import axios from 'axios';

const Login = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const location = useLocation();
    const { isLoggedIn } = useSelector((state) => state.user);
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleSubmit = async (values) => {
        dispatch(userLogin(values));
    }
    useEffect(() => {
        if (isLoggedIn) {
          navigate("/");
        }
      }, [isLoggedIn, navigate]);
    return (
        <Layout>
            <div className='auth-container'>
                <div className='hitachi-energy-logo'>
                    <img alt="Hitachi" title="Hitachi" src={HitachiEnergy} />
                </div>
                <div className='form-header'>
                    <h1>Log In to Hitachi</h1>
                    <Formik
                        enableReinitialize
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                    {({ errors, isValid, handleChange, setFieldValue, handleBlur, touched }) => {
                        return (
                        <Form className="login-form">
                            <Input
                                className={"input-box-login"}
                                autoComplete="off"
                                name="email"
                                type="email"
                                label="Email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={touched.email && errors.email}
                            />
                            <Input
                                className={"input-box-login"}
                                autoComplete="off"
                                name="password"
                                type={showPassword ? 'text' :"password"}
                                label="Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                errorMessage={touched.password && errors.password}
                            />
                            <button className="button button-register" disabled={!isValid} type="submit">
                                Login
                            </button>
                        </Form>
                        );
                    }}
                    </Formik>
                </div>
            </div>
        </Layout>
  )
}

export default Login