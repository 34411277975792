export const httpStatusType = {
    SUCCESS : "Success",
    BAD_REQUEST : "Bad Request",
    INTERNAL_SERVER_ERROR : "Internal Server Error",
    UNKNOWN_ERROR : "Unknown Error"
}

export const informationDisplayType = {
    MODAL : "Modal",
    FLAG : "Flag",
    NOTIFICATION : "Notification",
  }
  
export const informationType = {
    ERROR : "Error",
    WARNING : "Warning",
    INFO : "Info",
    SUCCESS : "Success",
  }
