import React, { useEffect, useRef, useState } from 'react'
import Layout from '../../common/components/layout/Layout'
import DirectedGraph from '../../common/components/charts/undirectedgraphwithedgeweights/edgeweight'
import {CountAnimate} from '../../common/components/counter/countanimate'
import qualityImage from '../../assets/images/quality.png'
import { useDispatch, useSelector } from 'react-redux'
import * as d3 from 'd3';
import { getDashboardCounts, getPredictionModelDetails, getRiskConnectionWeights } from '../../redux/actions'

const Dashboard = () => {

    const { predictionModelDetails, dashboardCounts, riskConnectionsWeights } = useSelector((state) => state.dash)
    
    const [clickNode, setClickNode] = useState(-1);
    const [clickNodeCountries, setClickNodeCountries] = useState(-1);
    
    // let itemRefs = {}
    const itemRefs =  useRef({});
    const itemRefsCountries = useRef({});
    
    const dispatch = useDispatch()
   
    const scrollToItem = (itemId) => {
        const targetItem = itemRefs.current[itemId];
        if (targetItem) {
            targetItem.scrollIntoView({ behavior: 'smooth' });
        }
      }
    const scrollToItemCountries = (itemId) => {
        const targetItem = itemRefsCountries.current[itemId];
        if (targetItem) {
            targetItem.scrollIntoView({ behavior: 'smooth' });
        }
    }
    
    useEffect(() => {
        dispatch(getPredictionModelDetails())
        dispatch(getDashboardCounts())
    }, [])

    return (
        <Layout>
            <div className='dashboard'>
                {
                    <div className='container-dashboard'>
                            <div className='col1'>
                                <div className='row1'>
                                    <div className='counts'>
                                    <div className='count'>
                                            <div className='count-icon'>
                                                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="46" height="46" rx="23" fill="#FF0026" fill-opacity="0.36"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 32.75C13 32.3358 13.3358 32 13.75 32H31.75C32.1642 32 32.5 32.3358 32.5 32.75C32.5 33.1642 32.1642 33.5 31.75 33.5H13.75C13.3358 33.5 13 33.1642 13 32.75Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13 20.1309C13 19.1666 13.7858 18.3809 14.75 18.3809H16.35C17.3142 18.3809 18.1 19.1666 18.1 20.1309V28.7509C18.1 29.7151 17.3142 30.5009 16.35 30.5009H14.75C13.7858 30.5009 13 29.7151 13 28.7509V20.1309ZM14.75 19.8809C14.6142 19.8809 14.5 19.9951 14.5 20.1309V28.7509C14.5 28.8866 14.6142 29.0009 14.75 29.0009H16.35C16.4858 29.0009 16.6 28.8866 16.6 28.7509V20.1309C16.6 19.9951 16.4858 19.8809 16.35 19.8809H14.75Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1992 16.9395C20.1992 15.9752 20.985 15.1895 21.9492 15.1895H23.5492C24.5134 15.1895 25.2992 15.9752 25.2992 16.9395V28.7495C25.2992 29.7137 24.5134 30.4995 23.5492 30.4995H21.9492C20.985 30.4995 20.1992 29.7137 20.1992 28.7495V16.9395ZM21.9492 16.6895C21.8134 16.6895 21.6992 16.8037 21.6992 16.9395V28.7495C21.6992 28.8852 21.8134 28.9995 21.9492 28.9995H23.5492C23.685 28.9995 23.7992 28.8852 23.7992 28.7495V16.9395C23.7992 16.8037 23.685 16.6895 23.5492 16.6895H21.9492Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.3984 13.75C27.3984 12.7858 28.1842 12 29.1484 12H30.7484C31.7126 12 32.4984 12.7858 32.4984 13.75V28.75C32.4984 29.7142 31.7126 30.5 30.7484 30.5H29.1484C28.1842 30.5 27.3984 29.7142 27.3984 28.75V13.75ZM29.1484 13.5C29.0127 13.5 28.8984 13.6142 28.8984 13.75V28.75C28.8984 28.8858 29.0127 29 29.1484 29H30.7484C30.8842 29 30.9984 28.8858 30.9984 28.75V13.75C30.9984 13.6142 30.8842 13.5 30.7484 13.5H29.1484Z" fill="white"/>
                                                </svg>
                                            </div>
                                        <CountAnimate targetNumber={dashboardCounts[0]?.PredectionsCount} step={2} msecs={60} />
                                        <div className='count-title'>
                                                Projects predicted by AI
                                        </div>
                                        </div>
                                        <div className='count-1'>
                                            <div className='count-icon'>
                                                <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect width="46" height="46" rx="23" fill="#C1C1C1"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.1757 18.2491C14.9873 17.2842 16.3789 16.8125 18.4166 16.8125H27.5833C29.6211 16.8125 31.0126 17.2842 31.8242 18.2491C32.628 19.2047 32.7044 20.4601 32.5756 21.6306L31.8878 28.9674C31.7868 29.9098 31.5502 30.943 30.7088 31.7117C29.8736 32.4748 28.5796 32.8542 26.6666 32.8542H19.3333C17.4204 32.8542 16.1264 32.4748 15.2912 31.7117C14.4497 30.943 14.2132 29.9098 14.1122 28.9674L14.1112 28.9583L13.4243 21.6306C13.2955 20.4601 13.372 19.2047 14.1757 18.2491ZM15.228 19.1342C14.7956 19.6483 14.6738 20.4208 14.7916 21.4852L14.7929 21.4967L15.4799 28.8254C15.5716 29.6773 15.7614 30.279 16.2186 30.6966C16.6827 31.1206 17.5612 31.4792 19.3333 31.4792H26.6666C28.4387 31.4792 29.3172 31.1206 29.7814 30.6966C30.2385 30.279 30.4284 29.6773 30.5201 28.8254L31.2082 21.4852C31.326 20.4208 31.2044 19.6483 30.772 19.1342C30.3461 18.6279 29.4597 18.1875 27.5833 18.1875H18.4166C16.5403 18.1875 15.6538 18.6279 15.228 19.1342Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0608 15.7129C20.0219 16.0112 20.0208 16.3501 20.0208 16.7667V17.5C20.0208 17.8797 19.7129 18.1875 19.3333 18.1875C18.9536 18.1875 18.6458 17.8797 18.6458 17.5L18.6458 16.7429C18.6457 16.3546 18.6457 15.9306 18.6974 15.5349C18.7509 15.1249 18.8656 14.6895 19.1344 14.3007C19.6997 13.4827 20.7353 13.1459 22.2666 13.1459H23.7333C25.2645 13.1459 26.3001 13.4827 26.8655 14.3007C27.1342 14.6895 27.2489 15.1249 27.3025 15.5349C27.3541 15.9306 27.3541 16.3547 27.3541 16.7429L27.3541 17.5C27.3541 17.8797 27.0463 18.1875 26.6666 18.1875C26.2869 18.1875 25.9791 17.8797 25.9791 17.5V16.7667C25.9791 16.3501 25.978 16.0112 25.939 15.7129C25.9009 15.4208 25.8323 15.2242 25.7344 15.0825C25.5664 14.8394 25.1353 14.5209 23.7333 14.5209H22.2666C20.8645 14.5209 20.4335 14.8394 20.2655 15.0825C20.1676 15.2242 20.0989 15.4208 20.0608 15.7129Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8547 23.6882C21.854 23.7537 21.854 23.8283 21.854 23.9167V24.8608C21.854 25.1189 21.8563 25.3087 21.8787 25.4698C21.9002 25.6246 21.9344 25.7 21.9635 25.7409C21.9958 25.7865 22.157 25.9792 22.9998 25.9792C23.8465 25.9792 24.0058 25.7847 24.0374 25.7395C24.0666 25.6978 24.1008 25.6213 24.122 25.4646C24.144 25.3017 24.1457 25.111 24.1457 24.8517V23.9167C24.1457 23.8283 24.1457 23.7537 24.145 23.6882C24.0795 23.6875 24.0049 23.6875 23.9165 23.6875H22.0832C21.9948 23.6875 21.9202 23.6875 21.8547 23.6882ZM22.0531 22.3125C22.0631 22.3125 22.0732 22.3125 22.0832 22.3125H23.9165C23.9265 22.3125 23.9366 22.3125 23.9466 22.3125C24.1493 22.3125 24.3548 22.3124 24.5224 22.331C24.6932 22.35 24.9771 22.4008 25.2047 22.6285C25.4324 22.8561 25.4832 23.1399 25.5022 23.3108C25.5208 23.4784 25.5207 23.6839 25.5207 23.8866C25.5207 23.8966 25.5207 23.9066 25.5207 23.9167V24.862C25.5207 25.1 25.5207 25.3817 25.4845 25.649C25.447 25.9263 25.3648 26.241 25.1637 26.5283C24.7347 27.1407 23.9774 27.3542 22.9998 27.3542C22.0277 27.3542 21.2722 27.1435 20.8417 26.536C20.6392 26.2504 20.5554 25.9367 20.5168 25.6593C20.4791 25.3881 20.479 25.1024 20.479 24.8608V23.9167C20.479 23.9066 20.479 23.8966 20.479 23.8866C20.479 23.6839 20.4789 23.4784 20.4975 23.3108C20.5165 23.1399 20.5673 22.8561 20.795 22.6285C21.0226 22.4008 21.3064 22.35 21.4773 22.331C21.6449 22.3124 21.8504 22.3125 22.0531 22.3125Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M32.4018 21.6791C32.6252 21.9861 32.5573 22.4161 32.2502 22.6394C30.0354 24.2502 27.505 25.2083 24.9192 25.5339C24.5425 25.5813 24.1987 25.3144 24.1512 24.9377C24.1038 24.5609 24.3707 24.2171 24.7474 24.1696C27.1117 23.8719 29.4213 22.9967 31.4415 21.5274C31.7485 21.3041 32.1785 21.372 32.4018 21.6791Z" fill="white"/>
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.8343 21.9426C14.0488 21.6292 14.4767 21.5491 14.79 21.7635C16.7598 23.1118 18.9811 23.9244 21.2432 24.1776C21.6205 24.2199 21.8922 24.56 21.8499 24.9374C21.8077 25.3147 21.4675 25.5864 21.0902 25.5441C18.6039 25.2657 16.1685 24.3733 14.0134 22.8982C13.7 22.6838 13.6199 22.2559 13.8343 21.9426Z" fill="white"/>
                                                </svg>
                                            </div>
                                            <CountAnimate targetNumber={dashboardCounts[0]?.predictionProjectsCount} step={1} msecs={20} />
                                            <div className='count-title'>
                                                Unique Predictions
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row2'>
                                    <div className="table-title">Current Trained Model</div>
                                    <table className='table-model-details'>
                                        <thead className='headings'>
                                            {
                                                <tr>
                                                    {predictionModelDetails.length && Object.keys(predictionModelDetails[0]).map((item,i) => (
                                                        <td key={i}>
                                                            {item}
                                                        </td>
                                                    ))}
                                                </tr>
                                            }
                                        </thead>
                                        <tbody className='table-content'>
                                            {
                                                predictionModelDetails.map((item) => (
                                                    <tr>
                                                        {Object.values(item).map((item,i) => (
                                                            <td key={i}>
                                                                {item}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        {
                            <div className='col2'>
                                <div className="card-title">Risk category Network Graph</div>
                                <div className='network-graph'>
                                    {
                                        Object.values(riskConnectionsWeights).length &&
                                        <DirectedGraph scrollToItem={scrollToItem} setClickNode={setClickNode} edgeweightNormalize={700} nodeweightNormalize={1} fontweightNormalize={100}
                                            riks_weights={riskConnectionsWeights?.riks_weights}
                                            risk_connection={riskConnectionsWeights?.risk_connection} />
                                    }
                                    <div className="info">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:'4px'}}>
                                        <g opacity="0.6">
                                        <mask id="mask0_148_1556" style={{maskType:'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
                                        <rect width="16" height="16" fill="#D9D9D9"/>
                                        </mask>
                                        <g mask="url(#mask0_148_1556)">
                                        <path d="M7.33325 11.3334H8.66658V7.33337H7.33325V11.3334ZM7.99992 6.00004C8.18881 6.00004 8.34714 5.93615 8.47492 5.80837C8.6027 5.6806 8.66658 5.52226 8.66658 5.33337C8.66658 5.14448 8.6027 4.98615 8.47492 4.85837C8.34714 4.7306 8.18881 4.66671 7.99992 4.66671C7.81103 4.66671 7.6527 4.7306 7.52492 4.85837C7.39714 4.98615 7.33325 5.14448 7.33325 5.33337C7.33325 5.52226 7.39714 5.6806 7.52492 5.80837C7.6527 5.93615 7.81103 6.00004 7.99992 6.00004ZM7.99992 14.6667C7.0777 14.6667 6.21103 14.4917 5.39992 14.1417C4.58881 13.7917 3.88325 13.3167 3.28325 12.7167C2.68325 12.1167 2.20825 11.4112 1.85825 10.6C1.50825 9.78893 1.33325 8.92226 1.33325 8.00004C1.33325 7.07782 1.50825 6.21115 1.85825 5.40004C2.20825 4.58893 2.68325 3.88337 3.28325 3.28337C3.88325 2.68337 4.58881 2.20837 5.39992 1.85837C6.21103 1.50837 7.0777 1.33337 7.99992 1.33337C8.92214 1.33337 9.78881 1.50837 10.5999 1.85837C11.411 2.20837 12.1166 2.68337 12.7166 3.28337C13.3166 3.88337 13.7916 4.58893 14.1416 5.40004C14.4916 6.21115 14.6666 7.07782 14.6666 8.00004C14.6666 8.92226 14.4916 9.78893 14.1416 10.6C13.7916 11.4112 13.3166 12.1167 12.7166 12.7167C12.1166 13.3167 11.411 13.7917 10.5999 14.1417C9.78881 14.4917 8.92214 14.6667 7.99992 14.6667ZM7.99992 13.3334C9.48881 13.3334 10.7499 12.8167 11.7833 11.7834C12.8166 10.75 13.3333 9.48893 13.3333 8.00004C13.3333 6.51115 12.8166 5.25004 11.7833 4.21671C10.7499 3.18337 9.48881 2.66671 7.99992 2.66671C6.51103 2.66671 5.24992 3.18337 4.21659 4.21671C3.18325 5.25004 2.66659 6.51115 2.66659 8.00004C2.66659 9.48893 3.18325 10.75 4.21659 11.7834C5.24992 12.8167 6.51103 13.3334 7.99992 13.3334Z" fill="#4D4D4D"/>
                                        </g>
                                        </g>
                                        </svg>
                                        Node size is # of countries, Edge-weight is # of common projects.</div>

                                </div>
                                <div className='project-details'>
                                    <div className='listView'>
                                        {
                                            Object.values(riskConnectionsWeights).length && riskConnectionsWeights.riks_weights.map((item,index) => {
                                                return (
                                                    <div ref={(element) => itemRefs.current[`${index}`] = element} className={clickNode==index ? 'clicked-project':'project-name'}>{item.label}</div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>    
      </Layout>
  )
}


export default Dashboard