// PieChart.js
import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const NivoPieChart = ({ decisionTreeGraphdata, height}) => {
    const data = Object.keys(decisionTreeGraphdata).map(item => ({ id:item,label: item, value: decisionTreeGraphdata[item] }))
    
    const colors = {
        'CONTINGENCY': 'rgba(193, 193, 193, 1)',
        'RISK_PROVISION': 'rgba(216, 34, 55, 1)',
        'PM_CONTINGENCY':'rgba(230, 230, 230, 1)',
        'MITIGATED': 'rgba(47, 0, 7, 1)',
        'COST': 'rgba(94, 0, 14, 1)',
        'NO_FCM_IMPACT': 'rgba(83, 85, 83, 1)',
        'REVENUE': 'rgba(192, 98, 112, 1)',
    }
    
    const getColor = bar => {
        return colors[bar.id]
    }
     
  return (
    <div style={{ height }}>
      <ResponsivePie 
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.09}
        isInteractive={true}
        padAngle={0.7}
        cornerRadius={3}
        colors={getColor}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
        radialLabelsSkipAngle={1}
        radialLabelsTextXOffset={6}
        radialLabelsTextColor="#333333"
        radialLabelsLinkOffset={0}
        radialLabelsLinkDiagonalLength={16}
        radialLabelsLinkHorizontalLength={24}
        radialLabelsLinkStrokeWidth={1}
        radialLabelsLinkColor={{ from: 'color' }}
        slicesLabelsSkipAngle={10}
        slicesLabelsTextColor="#333333"
        enableArcLabels={false}
        enableArcLinkLabels={false}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
            {
                anchor: 'left',
                direction: 'column',
                justify: false,
                translateX: -80,
                translateY: 0,
                itemsSpacing: 30,
                itemWidth: 100,
                padding: 10,
                itemHeight: 18,
                itemTextColor: '#999',
                itemDirection: 'left-to-right',
                itemOpacity: 1,
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemTextColor: '#000'
                        }
                    }
                ]
            }
        ]}
      />
    </div>
  );
};

export default NivoPieChart;
