import React, { useState, useEffect } from 'react';

export const CountAnimate = ({ targetNumber,msecs, step }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let animationInterval;

    if (count < targetNumber) {
      animationInterval = setInterval(() => {
        setCount((prevCount) => Math.min(prevCount + step, targetNumber));
      }, msecs); // Update every second (1000ms)
    }

    return () => {
      clearInterval(animationInterval);
    };
  }, [count, targetNumber]);

  return <div className='count-value'>{count}</div>;
};

