import React from "react";

const Preloader = ({ show }) => {
  return (
    <>
      {show && (
        <>
          <div id="gray-overlay"></div>
          <div id="loading"></div>
        </>
      )}
    </>
  );
};

export default Preloader;
