import { createReducer } from "@reduxjs/toolkit";
import {
    setDashboardCounts,
    setPredictionModelDetails,
    setmonthlyWisePredictionsCount,
    setRiskConnectionWeights
} from "../actions";

const initalState = {
    dashboardCounts: [],
    predictionModelDetails: [],
    monthlyWisePredictionCounts: {},
    riskConnectionsWeights: {}
};

const reducer = createReducer(initalState, (builder) => {
    return builder
        .addCase(setDashboardCounts, (state,action) => {
            state.dashboardCounts = action.payload;
        })
        .addCase(setPredictionModelDetails, (state, action) => {
            state.predictionModelDetails = action.payload;
        })
        .addCase(setmonthlyWisePredictionsCount, (state, action) => {
            state.monthlyWisePredictionCounts=action.payload
        })
        .addCase(setRiskConnectionWeights, (state,action) => {
            state.riskConnectionsWeights = action.payload;
        });
});
export { initalState as dashInitialState, reducer as dashReducer };
