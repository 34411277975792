import { string, object } from "yup";

export const decisionTreeInitialValues = {
    CategoryName: '',
    Duration: '',
    fcmEffectUSD: '',
    NetSalesPrice_ForecastUSD: '',
    BidValue: '',
    BidRange: '',
    project_name: '',
    country:'',
}

// export const decisionTreeInitialValues = {
//     CategoryName: '1. Customer',
//     Duration: '2',
//     fcmEffectUSD: '2',
//     NetSalesPrice_ForecastUSD: '2',
//     BidValue: '200',
//     BidRange: '0 - 2000',
//     project_name: 'project 6',
//     country:'india',
// }

export const decisionTreeValuesValidationSchema = object().shape({
    duration: string().required("Duration is required"),
    fcmEffectUsd: string().required("FcmEffect Usd is required"),
    netSalesPrice_ForecastUSD: string().required("netSalesPrice_ForecastUSD is required"),
    bidValue: string().required("Bid Value is required"),
    BidRange: string().required("Bid Range is required"),
});