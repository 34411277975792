// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history .close {
  margin-top: 10px;
  display: flex;
  flex-direction: row-reverse;
}`, "",{"version":3,"sources":["webpack://./src/features/history/history.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;EACA,aAAA;EACA,2BAAA;AAAR","sourcesContent":[".history{\n    .close{\n        margin-top: 10px;\n        display: flex;\n        flex-direction: row-reverse;\n        .history-cross{\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
