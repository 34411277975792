import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux'
import NivoBarChart from '../../common/components/nivocharts/barchart';
import NivoPieChart from '../../common/components/nivocharts/piechart';
import NivoRadarChart from '../../common/components/nivocharts/radar';
import ClearIcon from '@mui/icons-material/Clear';
import Radio from '@mui/material/Radio';
import { useNavigate } from 'react-router-dom';
import { decisionTreeInitialValues } from './utils';
// import { Checkbox } from '@mui/material';
import Checkbox,{checkboxClasses} from '@mui/material/Checkbox';
import { reSetPredictionData } from '../../redux/actions';


const Predictionsstep0 = ({
    setUSDGraphType, usdGraphType ,riskQuantificationGraphData, setFormData, formData, decisionTreeGraphdata, setShowGraph, showGraph, setRiskQuantificationGraphdata, setCheckedData, checkedData, setAllowedSteps, setActiveStep, setShowUSDGraph, showUSDGraph }) => {
    
    const { riskQuantificationData } = useSelector((state) => state.predictions);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const height = '450px';
    const handleClick = (e) => {
        setCheckedData({
            ...checkedData,
            [`${e.target.value}`]:e.target.checked
        })
    }

    useEffect(() => {
        let graphData = {}
        for (let key in checkedData) {
            if (checkedData[key]) {
                graphData[key]=riskQuantificationData[key]
            }
        }
        setRiskQuantificationGraphdata(graphData)
    },[checkedData])

    const getGraphs = () => {
        if (showGraph === 'bar') {
            return <NivoBarChart height={height} right={10} decisionTreeGraphdata={decisionTreeGraphdata} xAxisLabel="Risk category" yAxisLabel="AI Prediction Value"/>
        } else if (showGraph === 'pie') {
            return <NivoPieChart height={height} decisionTreeGraphdata={decisionTreeGraphdata} label="Preiction value"/>
        } else if (showGraph === 'radar') {
            return <NivoRadarChart height={height} decisionTreeGraphdata={decisionTreeGraphdata} label="Prediction value"/>
        }
    }

    const getusdGraphs = () => {
        if (usdGraphType === 'bar') {
            return <NivoBarChart height={height} right={-40} decisionTreeGraphdata={riskQuantificationGraphData} label="FCM effect USD" xAxisLabel="Risk category" yAxisLabel="FCM effect USD (in ‘000 $) - Predicted by AI"/>
        } else if (usdGraphType === 'pie') {
            return <NivoPieChart height={height} decisionTreeGraphdata={riskQuantificationGraphData} label="FCM effect USD"/>
        } else if (usdGraphType === 'radar') {
            return <NivoRadarChart height={height} decisionTreeGraphdata={riskQuantificationGraphData} label="FCM effect USD"/>
        }
    }

  return (
      <div className='predictions-step0'>
            <div className='action-area'>
                <div className='project-name'> Project X  - Risk Quantification </div>
                <div className='action-items'>
                    <dir className='edit-form'>
                        <Button onClick={() => {
                            setActiveStep(0)
                        }} size="small" className='submit'
                        sx={{
                            backgroundColor: 'white',
                            ":hover": {
                                backgroundColor: 'white',
                                border:'3px solid red'
                            },
                            color: 'red',
                            border:'2px solid red',
                            textTransform: 'none',
                            fontWeight:'bold'
                        }}
                        variant="outlined">Edit Current Prediciton Criteria</Button>
                    </dir>
                    <dir className='reset-form'>
                        <Button onClick={() => {
                            setFormData(decisionTreeInitialValues)
                            setActiveStep(0)
                            dispatch(reSetPredictionData())
                        }} size="small" className='submit'
                        sx={{
                            backgroundColor: '#ce0000',
                            ":hover": {
                                backgroundColor:'rgb(161, 52, 52)'
                            },
                            borderRadius: 'none',
                            color:'white',
                            textTransform:'none'
                        }}
                        variant="secondary">New Prediction</Button>
                    </dir>
                </div>
            </div>
            <div className='checkbox-area'>
                <div className='checkbox-container'>
                    {Object.keys(riskQuantificationData).map((item,i) => {
                        return (
                            <div className='checkbox-input'>
                                <Checkbox
                                    sx={{
                                        [`&, &.${checkboxClasses.checked}`]: {
                                            color: 'red',
                                        },
                                    }}
                                    className='checkbox' key={i} id={item} type='checkbox' name={item} value={item} onClick={handleClick} checked={checkedData[item]} />
                                <label key={item} htmlFor={item}>{item}</label>
                            </div>
                        )
                    })}
                
                </div>
            </div>
            <div className='graph-container'>
                    <div className='decisiontree-graph'>
                        <div key='graph-selectors' className='graphs-selectors'>
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                        color: 'red',
                                        },
                                    }}
                                    key='bar' type="radio" id="bar" name="chart-type" checked={showGraph==='bar'} value="Bar" onChange={()=>{setShowGraph('bar')} }
                                    />
                                <label key='bar1' htmlFor="bar">Bar</label>
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                        color: 'red',
                                        },
                                    }}
                                    key='pie' type="radio" id="pie" name="chart-type" checked={showGraph === 'pie'} value="Pie" onChange={() => { setShowGraph('pie') }} />
                                <label key='pie1' htmlFor="pie">Pie</label>
                                <Radio
                                    sx={{
                                        '&, &.Mui-checked': {
                                        color: 'red',
                                        },
                                    }}
                                    key='radar' type="radio" id="radar" name="chart-type" checked={showGraph === 'radar'} value="Radar" onChange={() => { setShowGraph('radar') }} />
                                <label key='radar1' htmlFor="radar">Radar</label>
                        </div>
                        <div key='step1-charts'>
                            {
                                getGraphs()
                            }
                        </div>  
                    </div>
                    {showUSDGraph &&
                        <div className='fcmeffectusd-graph'>
                        <div key='graph-selectors' className='graphs-selectors'>
                            <Radio
                                sx={{
                                    '&, &.Mui-checked': {
                                    color: 'red',
                                    },
                                }}
                                key='bar' type="radio" id="bar" name="chart-type" checked={usdGraphType === 'bar'} value="Bar" onChange={() => { setUSDGraphType('bar') }} />
                            <label key='bar1' htmlFor="bar">Bar</label>
                            <Radio
                                sx={{
                                    '&, &.Mui-checked': {
                                    color: 'red',
                                    },
                                }}
                                key='pie' type="radio" id="pie" name="chart-type" checked={usdGraphType === 'pie'} value="Pie" onChange={() => { setUSDGraphType('pie') }} />
                            <label key='pie1' htmlFor="pie">Pie</label>
                            <Radio
                                sx={{
                                    '&, &.Mui-checked': {
                                    color: 'red',
                                    },
                                }}
                                key='radar' type="radio" id="radar" name="chart-type" checked={usdGraphType === 'radar'} value="Radar" onChange={() => { setUSDGraphType('radar') }} />
                            <label key='radar1' htmlFor="radar">Radar</label>
                        </div>
                        <div key='step2-charts'>
                            {
                                getusdGraphs()
                            }
                        </div> 
                        </div>
                    }
            </div>
    </div>
  )
}

export default Predictionsstep0