import { put, takeLatest } from "redux-saga/effects";
import * as Api from '../../services/api.service'
import { httpStatusType } from "../../config/enums";
import { callWrapper } from "./sagas.callWrapper";
import {  httpResponseStatus } from "../../utils/apiHelper";
import {
    apiGetMasterData,apiSetMasterData,apiGetMasterDataFailed
} from '../actions'



export function* getMasterData(action) {
    try {
        if (apiGetMasterData.match(action)) {
        const res = yield callWrapper(Api.getMasterData, {
            ...action.payload,
            // tokenRequired: true,
        });
        if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
            const data = JSON.parse(res?.data);
            yield put(apiSetMasterData({ ...data}));
        } else {
          yield put(apiGetMasterDataFailed());
        }
      }
    } catch (err) {
      yield put(apiGetMasterDataFailed());
    }
}
  
  export const appSagas = [
    takeLatest(apiGetMasterData.type, getMasterData),
  ];