import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createInjectorsEnhancer } from "redux-injectors";
import { createRootReducer } from "./reducer/index";
import { rootSaga } from "./saga/index";

function configureAppStore(initialState) {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;
  // sagaMiddleware: Makes redux saga works
  const middlewares = [sagaMiddleware];
  const enhancers = [
    createInjectorsEnhancer({
      createReducer: createRootReducer,
      runSaga,
    }),
  ];
  const store = configureStore({
    reducer: createRootReducer(),
    middleware: [...middlewares],
    preloadedState: initialState,
    devTools: process.env.REACT_APP_ENVIRONMENT !== "production",
    enhancers,
  });
  sagaMiddleware.run(rootSaga);
  return store;
}

export { configureAppStore };