import { put, takeLatest } from "redux-saga/effects";
import * as Api from '../../services/api.service'
import { httpStatusType } from "../../config/enums";
import { callWrapper } from "./sagas.callWrapper";
import {  httpResponseStatus } from "../../utils/apiHelper";
import {
    getPredictionModelDetails,getmonthlyWisePredictionsCount,getRiskConnectionWeights,getDashboardCounts,
    setPredictionModelDetails,setmonthlyWisePredictionsCount,setRiskConnectionWeights,setDashboardCounts
} from '../actions'



export function* getPredictionModelDetailsAPI(action) {
    try {
        if (getPredictionModelDetails.match(action)) {
        const res = yield callWrapper(Api.getPredictionModelDetails, {
            ...action.payload
        });
        if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
            const data = JSON.parse(res?.data);
            yield put(setPredictionModelDetails(data?.data));
        } else {
            yield put(setPredictionModelDetails([]));

        }
      }
    } catch (err) {
        yield put(setPredictionModelDetails([]));
    }
}

export function* getDashboardCountsAPI(action) {
    try {
        if (getDashboardCounts.match(action)) {
        const res = yield callWrapper(Api.getDashboardCounts, {
            ...action.payload
        });
        if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
            const data = JSON.parse(res?.data);
            yield put(setDashboardCounts(data?.data));
        } else {
            yield put(setDashboardCounts([]));
        }
      }
    } catch (err) {
        yield put(setDashboardCounts([]));
    }
}

export function* getRiskConnectionWeightsAPI(action) {
    try {
        if (getRiskConnectionWeights.match(action)) {
        const res = yield callWrapper(Api.getRiskConnectionWeights, {
            ...action.payload
        });
        if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
            const data = JSON.parse(res?.data);
            yield put(setRiskConnectionWeights(data?.data));
        } else {
            yield put(setRiskConnectionWeights([]));
        }
      }
    } catch (err) {
        yield put(setRiskConnectionWeights([]));
    }
}
  
  export const dashboardSagas = [
    takeLatest(getPredictionModelDetails.type, getPredictionModelDetailsAPI),
    takeLatest(getDashboardCounts.type, getDashboardCountsAPI),
    // takeLatest(getmonthlyWisePredictionsCount.type, getmonthlyWisePredictionsCountAPI),
    takeLatest(getRiskConnectionWeights.type, getRiskConnectionWeightsAPI),
  ];