// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-react-select label {
  font-weight: bold;
  font-size: small;
}`, "",{"version":3,"sources":["webpack://./src/common/components/dropdown/dropdown.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,gBAAA;AAAR","sourcesContent":[".dropdown-react-select{\n    label{\n        font-weight: bold;\n        font-size:small;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
