import React, { useEffect, useState } from 'react'
import DropDown from '../../common/components/dropdown/dropdown'
import Input from '../../common/components/input/input'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button';
import { getPredictionData } from '../../redux/actions/index'
import "react-toastify/dist/ReactToastify.css";

export const PredictionsForm = ({  notify, dispatch, formData, setFormData, setAllowedSteps, setCheckedData, decisionTreeInitialValues }) => {

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const { masterdata } = useSelector((state) => state.app);

    const handleDropDownChange = (fieldName, value) => {
        setFormData({
            ...formData,
            [`${fieldName}`]:value
        })
    }
    const handleInputChange = (event) => {
        setFormData({
            ...formData,
            [`${event.target.name}`]:event.target.value
        })
    }

    const handleSubmit = () => {
        if (Object.values(formData).filter(i => i !== '').length === Object.values(formData).length) {
            dispatch(getPredictionData(formData))
            setIsButtonDisabled(true);
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 1000);

        } else {
            notify("please fill all the fields")
        }
    }

    const handleReset = () => {
        setFormData(decisionTreeInitialValues)
    }

    return (
        <div className='form-main'>
            <form className="predictions-form">
                    <div className='col1-input'>
                        <div className='item-name'>
                            <Input type={'text'} className={'input-prediction'} label={'Project Name '} name={'project_name'} placeholder={'Enter Project Name'} required={false}
                                disabled={false} onChange={handleInputChange}
                                value={formData.project_name}
                            />
                        </div>
                        <div className='item-name'>
                            <DropDown id='categoryName'
                                options={masterdata?.categories?.length>0 ? masterdata?.categories : []}
                                htmlFor={'catetoryName'}
                                required={false}
                                name={'CategoryName'}
                                label={'Main Risk Category'}
                                selectedOption={{value:formData.CategoryName,label:formData.CategoryName||'Select Category Name'}}
                                isSearchable={true}
                                onChange={(val)=>{handleDropDownChange('CategoryName',val.value)}}    
                                placeholder={'select category name'}
                                width={'100%'}
                            />
                        </div>
                        <div className='item-name'>
                            <DropDown id='bidRange'
                                options={masterdata?.bidRange?.length>0 ? masterdata?.bidRange : []}
                                htmlFor={'bidRange'}
                                required={false}
                                name={'Bid Range'}
                                label={'Project Bid Range (in ‘000 $)'}
                                selectedOption={{value:formData.BidRange,label:formData.BidRange||'Select Bid Range'}}
                                isSearchable={true}
                                onChange={(val)=>{handleDropDownChange('BidRange',val.value)}}    
                                placeholder={'select Bid Range'}
                                width={'100%'}
                            />
                        </div>
                    </div>
                    <div className='col1-input col-right'>
                        <div className='item-name'>
                            <DropDown id='countryName'
                                options={masterdata?.countries?.length>0 ? masterdata?.countries : []}
                                htmlFor={'countryName'}
                                required={false}
                                name={'countryName'}
                                label={'Country'}
                                selectedOption={{value:formData.country,label:formData.country||'Select Country'}}
                                isSearchable={true}
                                onChange={(val)=>{handleDropDownChange('country',val.value)}}    
                                placeholder={'select country'}
                                width={'100%'}
                            />
                        </div>
                        <div className='item-name'>
                            <DropDown id='duration'
                                options={masterdata?.duration?.length>0 ? masterdata?.duration : []}
                                htmlFor={'duration'}
                                required={false}
                                name={'Duration'}
                                label={'Project Duration in months'}
                                selectedOption={{value:formData.Duration,label:formData.Duration||'Select duration'}}
                                isSearchable={true}
                                onChange={(val)=>{handleDropDownChange('Duration',val.value)}}    
                                placeholder={'select duration in months'}
                                width={'100%'}
                            />
                        </div>
                        <div className='item-name'>
                                <Input type={'number'} className={'input-prediction'} label={'Bid Value (in ‘000 $)'} name={'BidValue'} placeholder={'Enter BidValue'} required={false}
                                    disabled={false} onChange={handleInputChange} min={0} value={formData.BidValue}
                                />
                        </div>
                    </div>
                    <div className='row2-input'>
                        <div className='item-name'>
                                <Input type={'number'} className={'input-prediction'} label={'Risk Amount Estimated by PM (in ‘000 $)'} name={'fcmEffectUSD'} placeholder={'Enter Fcm Effect USD'} required={false}
                                    disabled={false} onChange={handleInputChange} min={0} value={formData.fcmEffectUSD}
                                />
                        </div>
                        <div className='item-name'>
                                <Input type={'number'} className={'input-prediction'} label={'As Sold Price Forecast in Tender (in ‘000 $)'} name={'NetSalesPrice_ForecastUSD'} placeholder={'Enter NetSalesPrice_ForecastUSD'} required={false}
                                    disabled={false} onChange={handleInputChange} min={0} value={formData.NetSalesPrice_ForecastUSD}
                                />
                        </div>
                    </div>

                    <div className='submit-reset-button'>
                        <Button disabled={isButtonDisabled} onClick={handleReset} size="small" className='reset'
                            sx={{
                                backgroundColor: 'white',
                                ":hover": {
                                    backgroundColor: 'white',
                                    border:'3px solid red'
                                },
                                color: 'red',
                                border:'2px solid red',
                                textTransform: 'none',
                                fontWeight:'bold'
                            }}
                            variant="outlined">Reset</Button>
                        <Button disabled={isButtonDisabled} onClick={handleSubmit} size="small" className='submit'
                            sx={{
                                backgroundColor: '#ce0000',
                                ":hover": {
                                    backgroundColor:'rgb(161, 52, 52)'
                                },
                                textTransform:'none'
                            }}
                            variant="contained">Submit</Button>
                    </div>
                </form>
                    </div>
    )
}