import { createAction } from "@reduxjs/toolkit";

const APP = {
    API_CALL_IN_PROGRESS: "APP_API_CALL_IN_PROGRESS",
    API_CALL_COMPLETED: "APP_API_CALL_COMPLETED",
    API_SET_MASTER_DATA: "API_SET_MASTER_DATA",
    API_GET_MASTER_DATA: "API_GET_MASTER_DATA",
    API_GET_MASTER_DATA_FAILED: "API_GET_MASTER_DATA_FAILED",
};
  
const DASH = {
    GET_COUNTS: "GET_COUNTS",
    SET_COUNTS: "SET_COUNTS",
    GET_LAST_12_MONTHS_PREDICTIONS_COUNTS: "GET_LAST_12_MONTHS_PREDICTIONS_COUNTS",
    SET_LAST_12_MONTHS_PREDICTIONS_COUNTS: "SET_LAST_12_MONTHS_PREDICTIONS_COUNTS",
    GET_RISK_CONNECTIONS_WEIGHTS: "GET_RISK_CONNECTIONS_WEIGHTS",
    SET_RISK_CONNECTIONS_WEIGHTS: "SET_RISK_CONNECTIONS_WEIGHTS",
    GET_PREDICTION_MODEL_DETAILS:"GET_PREDICTION_MODEL_DETAILS",
    SET_PREDICTION_MODEL_DETAILS:"SET_PREDICTION_MODEL_DETAILS"
}

const USER = {
    LOGIN: "USER_LOGIN",
    LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    LOGIN_FAILED: "USER_LOGIN_FAILED",
    LOGOUT: "USER_LOGOUT",
    LOGOUT_SUCCESS: "USER_LOGOUT_SUCCESS"
}

const PREDICTIONS = {
    GET_PREDICTION_DATA: "GET_PREDICTION_DATA",
    GET_PREDICTION_DATA_FAILED: "SET_PREDICTION_DATA_FAILED",
    SET_PREDICTION_DATA: "SET_PREDICTION_DATA",
    RESET_PREDICTION_DATA: "RESET_PREDICTION_DATA",
    SET_QUESTION_DATA: "SET_QUESTION_DATA",
    SET_USD_DATA: "SET_USD_DATA",
    GET_RISK_QUATIFICATION_DATA:"GET_RISK_QUATIFICATION_DATA",
    SET_RISK_QUATIFICATION_DATA: "SET_RISK_QUATIFICATION_DATA",
    GET_RISK_QUATIFICATION_DATA_FAILED:"GET_RISK_QUATIFICATION_DATA_FAILED"
}

const ERROR = {
    SET_ERROR: "ERROR_SET_ERROR",
    HIDE_ERROR: "ERROR_HIDE_ERROR",
};


const actionType = {
    USER,
    APP,
    ERROR,
    PREDICTIONS,
    DASH
  };

export const userLogin = createAction(actionType.USER.LOGIN);
export const userLoginSuccess = createAction(actionType.USER.LOGIN_SUCCESS);
export const userLoginFailed = createAction(actionType.USER.LOGIN_FAILED);
export const userLogout = createAction(actionType.USER.LOGOUT);
export const userLogoutSuccess = createAction(actionType.USER.LOGOUT_SUCCESS);

export const getDashboardCounts =createAction(actionType.DASH.GET_COUNTS)
export const getmonthlyWisePredictionsCount = createAction(actionType.DASH.GET_LAST_12_MONTHS_PREDICTIONS_COUNTS)
export const getRiskConnectionWeights = createAction(actionType.DASH.GET_RISK_CONNECTIONS_WEIGHTS)
export const getPredictionModelDetails = createAction(actionType.DASH.GET_PREDICTION_MODEL_DETAILS)
export const setDashboardCounts =createAction(actionType.DASH.SET_COUNTS)
export const setmonthlyWisePredictionsCount = createAction(actionType.DASH.SET_LAST_12_MONTHS_PREDICTIONS_COUNTS)
export const setRiskConnectionWeights = createAction(actionType.DASH.SET_RISK_CONNECTIONS_WEIGHTS)
export const setPredictionModelDetails = createAction(actionType.DASH.SET_PREDICTION_MODEL_DETAILS)

export const apiCallInProgress = createAction(actionType.APP.API_CALL_IN_PROGRESS);
export const apiCallCompleted = createAction(actionType.APP.API_CALL_COMPLETED);
export const apiGetMasterData = createAction(actionType.APP.API_GET_MASTER_DATA);
export const apiSetMasterData = createAction(actionType.APP.API_SET_MASTER_DATA);
export const apiGetMasterDataFailed = createAction(actionType.APP.API_GET_MASTER_DATA_FAILED);

export const getPredictionData = createAction(actionType.PREDICTIONS.GET_PREDICTION_DATA)
export const getPredictionDataFailed = createAction(actionType.PREDICTIONS.GET_PREDICTION_DATA_FAILED)
export const setPredictionData = createAction(actionType.PREDICTIONS.SET_PREDICTION_DATA)
export const reSetPredictionData = createAction(actionType.PREDICTIONS.RESET_PREDICTION_DATA)

export const setError = createAction(actionType.ERROR.SET_ERROR);
export const hideError = createAction(actionType.ERROR.HIDE_ERROR);

export default actionType