const requestConfig = {
    url: '',
    method: 'get', // default
    baseURL: '',
    transformRequest: [
      function transformRequest(data,headers) {
        // transform request
        return data;
      }
    ],
    transformResponse: [
      function transformResponse(data) {
            // transform response
        return data;
      }
    ],
    headers: {},
    params: {},
    timeout: 330000,
    withCredentials: false, // default
    responseType: 'json', // default
    maxContentLength: 50000,
    validateStatus(status) {
      return status >= 200 && status < 300; // default
    },
    maxRedirects: 5, // default
  };
  export default requestConfig