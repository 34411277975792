import * as API from "../utils/apiHelper";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const login = (data) => {
  const url = `${API_ENDPOINT}/api/v1/auth/login`; // dummy api
  
  const postData = {username:data?.email,password:data?.password}
  return API.post(url, postData);
};
export const logout = () => {
    const url = `${API_ENDPOINT}/api/v1/auth/logout`;
    return API.get(url);
};

export const getMasterData = (data) => {
    const url = `${API_ENDPOINT}/api/v1/ai/metadata`;
    return API.get(url, {list:Object.values(data)});
}
export const getDashboardCounts = () => {
    const url = `${API_ENDPOINT}/api/v1/ai/dashboard/get-counts`;
    return API.get(url);
}
export const getmonthlyWisePredictionsCount = () => {
    const url = `${API_ENDPOINT}/api/v1/ai/dashboard/get-lastTwelveMonths-prediction-counts`;
    return API.get(url);
}
export const getRiskConnectionWeights = (data) => {
    const url = `${API_ENDPOINT}/api/v1/ai/dashboard/get-risk-connections-weights`;
    return API.get(url);
}
export const getPredictionModelDetails = (data) => {
    const url = `${API_ENDPOINT}/api/v1/ai/dashboard/get-prediction-model-details`;
    return API.get(url);
}

export const getPredictionData = (data) => {
  const url = `${API_ENDPOINT}/api/v1/ai/get-predection`;
  return API.post(url, data);
};

export const getAccessToken = (data) => {
  const url = `${API_ENDPOINT}/token/refresh`;
  return API.post(url, data);
};

export const profileData = (params) => {
  const url = `${API_ENDPOINT}/get_update`;
  return API.get(url, params);
};