import { string, object } from "yup";
import { emailRegex } from "../../constants/regex";

const names = {
  emailName: "email",
  passwordName: "password",
};

export const initialValues = {
  [names.emailName]: "",
  [names.passwordName]: "",
};

export const validationSchema = object().shape({
  email: string().matches(emailRegex, "Invalid email").required("Email is required"),
  password: string().required("Password is required"),
});
