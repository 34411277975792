import { createReducer } from "@reduxjs/toolkit";
import {
  apiCallCompleted,
    apiCallInProgress,
    apiGetMasterData,
    apiSetMasterData,
  apiGetMasterDataFailed
} from "../actions";

const initalState = {
    inProgress: false,
    masterdata: {
        categories: [],
        duration: [],
        bidRange: [],
        countries: []
    }
};

const reducer = createReducer(initalState, (builder) => {
    return builder
        .addCase(apiCallInProgress, (state) => {
            state.inProgress = true;
        })
        .addCase(apiCallCompleted, (state) => {
            state.inProgress = false;
        })
        .addCase(apiSetMasterData, (state, action) => {
            state.masterdata.categories = action?.payload?.data[0]?.value.map((item) => ({value:item,label:item}))
            state.masterdata.duration = action?.payload?.data[1]?.value.map((item) => ({value:item,label:item}))
            state.masterdata.bidRange = action?.payload?.data[2]?.value.map((item) => ({value:item,label:item}))
            state.masterdata.countries = action?.payload?.data[3]?.value.map((item) => ({value:item,label:item}))
        })
        .addCase(apiGetMasterDataFailed, (state) => {
            state.masterdata = {};
        });
});
export { initalState as appInitialState, reducer as appReducer };
