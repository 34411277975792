import { BrowserRouter, Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import Login from "./features/login/login";
import Dashboard from "./features/dashboard/dashboard";
import Predictions from "./features/predictions/predictions";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as notify from "./utils/notifications";
import './App.scss'
import { useEffect } from "react";
import { apiGetMasterData, getDashboardCounts, getPredictionModelDetails, getRiskConnectionWeights } from "./redux/actions";
import NivoBarChart from "./common/components/nivocharts/barchart";
import NivoPieChart from "./common/components/nivocharts/piechart";
import NivoRadarChart from "./common/components/nivocharts/radar";
const ProtectedRoute = ({ isLoggedIn, children }) => {
    const { pathname } = useLocation();
    if (!isLoggedIn) {
      const currentRoute = pathname === "/" ? "" : pathname;
      return <Navigate to={"login"} state={{ redirectUrl: currentRoute }} replace />;
    } else {
      return children ? children : <Outlet />;
    }
  };

function App() {
    const { isLoggedIn } = useSelector((state) => state.user);
    const { masterdata } = useSelector((state) => state.app);
    const {
        error,
        isOpen,
        informationType: infoType,
    } = useSelector((state) => state.error);
    
    const dispatch  = useDispatch()

    useEffect(() => {
        if (error && isOpen) {
          notify.show({ toast, message: error?.message, type: infoType });
        }
    }, [error,infoType, isOpen]);

    useEffect(() => {
        if (isLoggedIn && masterdata?.categories?.length <= 0) {
            dispatch(apiGetMasterData(['categories', 'duration','bid-range','countries']))
            dispatch(getPredictionModelDetails())
            dispatch(getDashboardCounts())
            dispatch(getRiskConnectionWeights())
        }
    }, [isLoggedIn, masterdata?.categories?.length, dispatch])
    const data = {
        RISK_PROVISION: 0.968, 
        MITIGATED: 0.026, 
        CONTINGENCY: 0.004, 
        COST: 0.002, 
        NO_FCM_IMPACT: 0.0, 
        REVENUE: 0.0, 
        predicted_fcmEffectUSD: 0
      }
   

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path='/' element={<ProtectedRoute isLoggedIn={isLoggedIn} />}>
                        <Route path="predictions" element={<Predictions />} />
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route index element={<Predictions />} />
                        <Route path="bar" element={<NivoRadarChart decisionTreeGraphdata={data} xAxisLabel={'xaxis'} yAxisLabel={"yaxis"}/>} />
                    </Route>
                </Routes>
            </BrowserRouter>
            <ToastContainer />
        </>
        
        
  );
}

export default App;
