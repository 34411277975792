import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const DirectedGraph = ({risk_connection, riks_weights, setClickNode, scrollToItem, edgeweightNormalize, nodeweightNormalize, fontweightNormalize}) => {
    const svgRef = useRef(null);
    
    // const riks_weights = [
    //     { id: 'A' ,weight:1},
    //     { id: 'B' ,weight:2},
    //     { id: 'C' ,weight:3},
    //     { id: 'D' ,weight:4},
    //     { id: 'E' ,weight:5},
    //     { id: 'A1' ,weight:1},
    //     { id: 'B1' ,weight:2},
    //     { id: 'C1' ,weight:3},
    //     { id: 'D1' ,weight:4},
    //     { id: 'E1' ,weight:5}
    // ];


    // const risk_connection = [
    //     { source: 'A', target: 'B' ,value:10},
    //     { source: 'B', target: 'C' ,value:10},
    //     { source: 'A', target: 'C' ,value:20},
    //     { source: 'B', target: 'D' ,value:35},
    //     { source: 'C', target: 'D' ,value:40},
    //     { source: 'E', target: 'D' ,value:30},
    //     { source: 'E1', target: 'D' ,value:30},
    //     { source: 'B1', target: 'D' ,value:30},
    //     { source: 'A1', target: 'B1' ,value:10},
    //     { source: 'A1', target: 'C1' ,value:20},
    //     { source: 'B1', target: 'D1' ,value:35},
    //     { source: 'C1', target: 'D1' ,value:40},
    //     { source: 'E1', target: 'D1' ,value:30},
    // ];

    const links = risk_connection.map(d => ({...d}));
    const nodes = riks_weights.map(d => ({...d}));



  useEffect(() => {
   
        // Specify the dimensions of the chart.
        const width = 1000;
        const height = 1100;
      
        // Specify the color scale.
        // const color = d3.scaleOrdinal(d3.schemeCategory10);
      
        // The force simulation mutates links and nodes, so create a copy
        // so that re-evaluating this cell produces the same result.
        // const links = edges.map(d => ({...d}));
        // const nodes = nodes1.map(d => ({...d}));
        
      
        // Create a simulation with several forces.
      const simulation = d3.forceSimulation(nodes)
          .force("link", d3.forceLink(links).id(d => d.id))
          .force("charge", d3.forceManyBody().strength(-900))
          .force("center", d3.forceCenter(width / 2, height / 2))
          .force('collision', d3.forceCollide().radius(function (d) {
              return d.radius
          }))
          .on("tick", ticked)
      
        // Create the SVG container.
        const svg = d3
        .select(svgRef.current)
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto;");
      
        // Add a line for each link, and a circle for each node.
        const link = svg.append("g")
            .attr("stroke", "rgba(0,0,0)")
            .attr("stroke-opacity", 0.6)
            .selectAll()
            .data(links)
            .join("line")
            .attr("stroke-width", d => d.value/edgeweightNormalize) //to normalize edge weight divide by const 
          
    const node = svg.append("g")
        .attr("stroke", "rgb(126,0,10)")
        .attr("stroke-width", 1.5)
        .selectAll()
        .data(nodes)
        .join("g")
        .attr('class', 'node')
        .attr("transform", d => `translate(${d.x},${d.y})`); // Set initial position
    
    // Append circles inside each group
    node.append("circle")
        .attr("r", d => Math.sqrt(d.weight) / nodeweightNormalize)
        .attr("fill", 'rgb(255,0,38)');
    
    // Append text inside each group
    node.append("text")
        .text((d,i) => i)
        .attr('font-size', d=>d.weight/fontweightNormalize)
        .attr('text-anchor', 'middle')  // Center the text horizontally
        .attr('dy', 2)
        // .attr('dy', d => Math.sqrt(d.weight) / 2 + 4)
        
            
      
    node.append("title")
        .text(d => d.id);

    
    // Add a drag behavior.
      node.call(d3.drag()
          .on("start", dragstarted)
          .on("drag", dragged)
          .on("end", dragended))
          .on('click', function (event, d) {
            const clickedNode = d3.select(this);
              setClickNode(d.index)
            //   scrollToItem(d.index)
              scrollToItem(`${d.index}`)
            // Toggle visibility of text based on the current opacity
            // const isTextVisible = parseFloat(clickedNode.select('text').style('opacity'));
            // clickedNode.select('circle')
            //     .style('color', 'black'); // Toggle opacity on click
        
            // // Set or clear the title attribute for tooltip
            // const titleContent = isTextVisible ? '' : d.id;
              // clickedNode.select('circle').attr('fill', 'black');
            // clickedNode.classed('highlight', !clickedNode.classed('highlight'));

            // // Remove the 'highlight' class from all other nodes
            // svg.selectAll('.node').filter(node => node !== clickedNode.node()).classed('highlight', false);

        })
      
        // Set the position attributes of links and nodes each time the simulation ticks.
      function ticked() {
          node.attr("transform", d => `translate(${d.x},${d.y})`);

          link
              .attr("x1", d => d.source.x)
              .attr("y1", d => d.source.y)
              .attr("x2", d => d.target.x)
              .attr("y2", d => d.target.y);
      
          node
              .attr("cx", d => d.x)
              .attr("cy", d => d.y);
        }
      
        // Reheat the simulation when drag starts, and fix the subject position.
        function dragstarted(event) {
          if (!event.active) simulation.alphaTarget(0.3).restart();
          event.subject.fx = event.subject.x;
          event.subject.fy = event.subject.y;
        }
      
        // Update the subject (dragged node) position during drag.
        function dragged(event) {
          event.subject.fx = event.x;
          event.subject.fy = event.y;
        }
      
        // Restore the target alpha so the simulation cools after dragging ends.
        // Unfix the subject position now that it’s no longer being dragged.
        function dragended(event) {
          if (!event.active) simulation.alphaTarget(0);
          event.subject.fx = null;
          event.subject.fy = null;
        }
      
        // When this cell is re-run, stop the previous simulation. (This doesn’t
        // really matter since the target alpha is zero and the simulation will
      // stop naturally, but it’s a good practice.)
      return (() => {
          simulation.stop();
      })
    
      
  }, [riks_weights]);

  return (
    <svg ref={svgRef}></svg>
  );
};

export default DirectedGraph;
