import { put, takeLatest } from "redux-saga/effects";
import * as Api from '../../services/api.service'
import { httpStatusType } from "../../config/enums";
import { callWrapper } from "./sagas.callWrapper";
import {  httpResponseStatus } from "../../utils/apiHelper";
import {
    getPredictionData,
    getPredictionDataFailed,
    setPredictionData
} from '../actions'



export function* getPredictionDataFromAI(action) {
    try {
        if (getPredictionData.match(action)) {
        const res = yield callWrapper(Api.getPredictionData, {
            ...action.payload
        });
        if (httpResponseStatus(res?.status) === httpStatusType.SUCCESS) {
            const data = JSON.parse(res?.data);
            yield put(setPredictionData(data.data));
        } else {
          yield put(getPredictionDataFailed());
        }
      }
    } catch (err) {
      yield put(getPredictionDataFailed());
    }
  }

  export const predictionSagas = [
    takeLatest(getPredictionData.type, getPredictionDataFromAI),
  ];