import React, { useEffect, useState } from 'react'
import Layout from '../../common/components/layout/Layout'
import { useDispatch,useSelector } from 'react-redux'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { decisionTreeInitialValues } from './utils'
import Predictionsstep0 from './predictionsstep0'
import { PredictionsForm } from './predictionsform'
import { reSetPredictionData } from '../../redux/actions';



const Predictions = () => {

    const [activeStep, setActiveStep] = useState(0)
    const [showGraph, setShowGraph] = useState('pie')
    const [showUSDGraph, setShowUSDGraph] = useState(true)
    const [usdGraphType, setUSDGraphType] =useState('bar')
    const [formData, setFormData] = useState(decisionTreeInitialValues)
    const [riskQuantificationGraphData, setRiskQuantificationGraphdata] = useState({})
    const [checkedData, setCheckedData] = useState({
        'CONTINGENCY': true,
        'PM_CONTINGENCY': true,
        'RISK_PROVISION':true,
        'COST': true,
        'MITIGATED':true,
        'NO_FCM_IMPACT':true,
        'REVENUE': true,
    })
    
    const dispatch = useDispatch();
    
    const { predictionsData } = useSelector((state) => state.predictions);
    
    const notify = (message) => toast(message);
    
    useEffect(() => {
        if (Object.values(predictionsData).length > 1) {
            setActiveStep(1)
            setShowGraph('pie')
        }
        return () => {
            // dispatch(reSetPredictionData())
        }
    }, [predictionsData])

    useEffect(() => {
        if (riskQuantificationGraphData && Object.values(riskQuantificationGraphData).length > 0) {
            setShowUSDGraph(true)
        } else {
            setShowUSDGraph(false)
        }
        return () => {
            // dispatch(reSetPredictionData())
        }
    }, [riskQuantificationGraphData])

    const getStep = () => {
        if (activeStep === 0) {
            return <PredictionsForm setActiveStep={setActiveStep} dispatch={dispatch} notify={notify}
                setFormData={setFormData} formData={formData} decisionTreeInitialValues={decisionTreeInitialValues} />
        } else if (activeStep === 1) {
            return <Predictionsstep0 setFormData={setFormData} decisionTreeGraphdata={predictionsData} setShowGraph={setShowGraph}
                showGraph={showGraph} activeStep={activeStep} checkedData={checkedData} setCheckedData={setCheckedData}
                formData={formData} setShowUSDGraph={setShowUSDGraph} showUSDGraph={showUSDGraph}
                setActiveStep={setActiveStep} setRiskQuantificationGraphdata={setRiskQuantificationGraphdata}
                riskQuantificationGraphData={riskQuantificationGraphData}
                usdGraphType={usdGraphType} setUSDGraphType={setUSDGraphType}
            />
        }
    }

    return (
        <Layout>
            {
                // <LabelStepper activeStep={activeStep} setActiveStep={setActiveStep} allowedSteps={allowedSteps} />
            }
            {
                getStep()
            }
      </Layout>
  )
}





export default Predictions