import React from 'react'
import Layout from '../../common/components/layout/Layout'
import CloseIcon from '@mui/icons-material/Close';
import HistoryTable from './table';

const PredictionHistory = ({setShowHistory}) => {
  return (

    <div className='history'>
        <div className='close'>
            <div className='history-cross' onClick={()=>setShowHistory(false)}>
                <CloseIcon />
            </div>
        </div>      
        <div className='table'>
            <HistoryTable/>
        </div>
    </div>
  )
}

export default PredictionHistory