import { createReducer } from "@reduxjs/toolkit";
import { informationDisplayType, informationType } from "../../config/enums";
import {
    hideError,
    setError,
} from "../actions";


const initalState = {
    error: null,
    isOpen: false,
    notificationType: informationDisplayType.NOTIFICATION,
    errorType: null, // Http Error type
    informationType: informationType.INFO // Notification popup type
   };

const reducer = createReducer(initalState, (builder) => {
  return builder
    // .addCase(setError, (state, action) => {
    //   const { error,notificationType, errorType  } = action.payload;
    //     state.error = error;
    //     state.notificationType = notificationType;
    //     state.errorType = errorType;
    //     state.isOpen = notificationType !== informationDisplayType.FLAG;
    // })
    .addCase(hideError, (state) => {
        state.error = null;
        state.isOpen = false;
        state.errorType = null;
    })
    .addDefaultCase((state, action) => {
        if(action && action?.payload) {
          const { error,notificationType, errorType,informationType  } = action.payload;
          if(error) {
            state.error = error;
            state.notificationType = notificationType;
            state.errorType = errorType;
            state.isOpen = notificationType !== informationDisplayType.FLAG;
            state.informationType = informationType;
          }
        }
    })
});

export { initalState as errorInitialState, reducer as errorReducer };
