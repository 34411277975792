import axios from "axios";
import requestConfig from "../config/apiConfig";
import { httpStatusType } from "../config/enums";
import { getAccessToken } from "../services/api.service";

let refreshTokenPromise;
axios.interceptors.response.use(
    (response) => {
        return response
    },
  (error) => {
    const { response, config } = error;
    const data = JSON.parse(response?.data);
    const refreshEndPoint = `${process.env.REACT_APP_API_ENDPOINT}/token/refresh`;
    if (response.status === 401 && data?.error?.code === "2000" && refreshEndPoint !== config.url) {
      if (!refreshTokenPromise) {
        const refresh = JSON.parse(localStorage.getItem("refreshToken"));
        refreshTokenPromise = getAccessToken({ refresh }).then((response) => {
          refreshTokenPromise = null;

          const { data } = JSON.parse(response.data);

          return data.access;
        });
      }

      return refreshTokenPromise.then((token) => {
        saveToLocalStorage("token", token);
        error.config.headers["Authorization"] = token;
        return axios.request(error.config);
      });
    }
    refreshTokenPromise = null;
    return Promise.reject(error);
  }
);

export const getFromLocalStorage = async (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};
export const saveToLocalStorage = async (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (err) {
    // Ignoring write error as of now
  }
};
export const clearFromLocalStorage = async (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (err) {
    return false;
  }
};
async function getRequestConfig(apiConfig) {
  let config = Object.assign({}, requestConfig);
  const session = await getFromLocalStorage("token");
  if (apiConfig) {
    config = Object.assign({}, requestConfig, apiConfig);
  }
  if (session) {
    config.headers["authorization"] = session;
  }
  return config;
}

export const get = async (url, params, apiConfig) => {
  const config = await getRequestConfig(apiConfig);
  config.params = params;
  const request = axios.get(url, config);
  return request;
};

export const post = async (url, data, apiConfig) => {
  const config = await getRequestConfig(apiConfig);
  let postData = {};
  if (
    apiConfig &&
    apiConfig.headers &&
    apiConfig.headers["Content-Type"] &&
    apiConfig.headers["Content-Type"] !== "application/json"
  ) {
    postData = data;
    axios.defaults.headers.post["Content-Type"] = apiConfig.headers["Content-Type"];
  } else {
    postData = JSON.stringify(data);
    axios.defaults.headers.post["Content-Type"] = "application/json";
  }
    const request = axios.post(url, postData, config);
  return request;
};
export const put = async (url, data) => {
  const config = await getRequestConfig();
  config.headers["Content-Type"] = "application/json";
  const request = axios.put(url, JSON.stringify(data), config);
  return request;
};
export const patch = async (url, data) => {
  const config = await getRequestConfig();
  config.headers["Content-Type"] = "application/json";
  const request = axios.patch(url, JSON.stringify(data), config);
  return request;
};
export const deleteResource = async (url, params) => {
  const config = await getRequestConfig();
  config.params = params;
  const request = axios.delete(url, config);
  return request;
};

export const httpResponseStatus = (status) => {
  if (status) {
    if (status >= 200 && status <= 299) {
      return httpStatusType.SUCCESS;
    } else if (status >= 400 && status <= 499) {
      return httpStatusType.BAD_REQUEST;
    } else if (status >= 500 && status <= 599) {
      return httpStatusType.INTERNAL_SERVER_ERROR;
    } else {
      return httpStatusType.UNKNOWN_ERROR;
    }
  }
  return httpStatusType.ERROR;
};
