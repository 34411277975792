// RadarChart.js
import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';

const NivoRadarChart = ({ decisionTreeGraphdata, height}) => {
    // const data = [
    //     { label: 'Category A', value: 80 },
    //     { label: 'Category B', value: 60 },
    //     { label: 'Category C', value: 40 },
    //     { label: 'Category D', value: 70 },
    //     { label: 'Category E', value: 90 },
    // ];
  const data = Object.keys(decisionTreeGraphdata).map(item => ({ label: item, value: decisionTreeGraphdata[item] }))
  
  return (
    <div style={{ height }}>
      <ResponsiveRadar
        data={data}
        keys={['value']}
        indexBy="label"
        maxValue="auto"
        margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
        curve="linearClosed"
        borderWidth={2}
        borderColor={{ from: 'color' }}
        gridLevels={5}
        gridShape="circular"
        gridLabelOffset={36}
        enableDots={true}
        dotSize={10}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={2}
        dotBorderColor={{ from: 'color' }}
        enableDotLabel={true}
        dotLabel="value"
        dotLabelYOffset={-12}
        colors={{ scheme: 'set1' }}
        fillOpacity={0.25}
        blendMode="multiply"
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );
};

export default NivoRadarChart;
