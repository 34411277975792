import React from 'react'
import Select from 'react-select';

const DropDown = ({ onChange, selectedOption, options, isSearchable, placeholder, label, htmlFor, required,width, paddingLeft }) => {


  return (
      <div className='dropdown-react-select'>
        <label htmlFor={htmlFor}>
            {label} <span>{required ? "*" : ""}</span>
        </label>
          <Select
            id={htmlFor}
            value={selectedOption}
            options={options}
            onChange={onChange}
            isSearchable={isSearchable} 
            placeholder={placeholder}
            styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                    borderColor: state.isFocused ? '' : 'grey',
                    width: width,
                    marginTop: '1%',
                    height:'10%',
                    // borderTop: 'none',
                    // borderLeft: 'none',
                    // borderRight: 'none',
                    // borderBottomRightRadius: 'none',
                    // borderBottomLeftRadius: 'none',
                    paddingLeft: paddingLeft
                }),
                menu: (base) => ({
                    ...base,
                    width: width
               }),
              }}
        />
  </div>
  )
}

export default DropDown