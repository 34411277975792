import { createReducer } from "@reduxjs/toolkit";
import {
  userLoginFailed,
  userLoginSuccess,
  userLogoutSuccess
} from "../actions";
import jwt_decode from "jwt-decode";


const initalState = {
  isLoggedIn: false,
  authData: {
    token: ""
  },
  details: null,
};

const reducer = createReducer(initalState, (builder) => {
  return builder
      .addCase(userLoginSuccess, (state, action) => {
      const { token } = action.payload;
      state.isLoggedIn = true;
      state.authData = {
        token: token
      };
    })
    .addCase(userLoginFailed, () => {
      return initalState;
    })
    .addCase(userLogoutSuccess, () => {
      return initalState;
    });
});

export { initalState as userInitialState, reducer as userReducer };
