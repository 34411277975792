// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlight circle {
  fill: lightblue; /* Adjust the highlight color as needed */
}`, "",{"version":3,"sources":["webpack://./src/common/components/charts/undirectedgraphwithedgeweights/edgeweights.scss"],"names":[],"mappings":"AAAA;EACI,eAAA,EAAA,yCAAA;AACJ","sourcesContent":[".highlight circle {\n    fill: lightblue; /* Adjust the highlight color as needed */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
